<template>
    <div>
        <div class="header">
            <ds-header :title="title" style="width: 100%"></ds-header>
            <a-button type="primary" @click="showConfirm">返回</a-button>
        </div>
        <a-steps :current="current">
            <a-step>
                <template slot="title">
                    步骤一
                </template>
                <span slot="description">活动信息</span>
            </a-step>
            <a-step title="步骤二" description="活动设置" />
            <a-step title="步骤三" description="问卷设置" />
        </a-steps>
        <div style="width: 1000px;margin:  0 auto;">
            <a-form-model v-show="current === 0" ref="ruleForm" :model="form" :labelCol="{ style: 'width: 120px' }"
                labelAlign="right" :rules="rules">
                <a-form-model-item label="活动标题" prop="activityTitle">
                    <div>
                        <a-input style="width: 800px" placeholder="请输入活动标题，不超过40个字" :max-length="40"
                            v-model.trim="form.activityTitle" />
                        <p style="text-align: right;">{{ form.activityTitle.length }}/40</p>
                    </div>
                </a-form-model-item>
                <a-form-model-item label="活动封面" prop="activityImage">
                    <div class="upload-box" v-if="!form.activityImage" @click="visible2 = true">
                        <a-icon
                            style="font-size:14px;position: absolute;left: 50%;top: 50%;transform: translate(-50%,-50%);"
                            type="plus" />
                    </div>
                    <a-upload v-else list-type="picture-card" class="avatar-uploader" :show-upload-list="true"
                        :file-list="activityImagefileList" :action="IMG_API + '/oss/files'"
                        @preview="specialPicturePreview" :before-upload="beforespecialPictureUpload"
                        @change="handleSpPicChange">
                        <div v-if="!form.activityImage">
                            <a-icon :type="spPicLoading ? 'loading' : 'plus'" />
                        </div>
                    </a-upload>
                    <a-modal :zIndex="100000" :visible="specialPictureVisible" :footer="null"
                        @cancel="specialPictureVisible = false">
                        <img :src="form.activityImage" alt="avatar" class="imgUpload"
                            style="width: 400px;margin-top: 20px;" />
                    </a-modal>
                    <span>封面图片比例需为3:2，大小不超过10M格式支持jpg/png/jpeg类型</span>
                </a-form-model-item>
                <a-form-model-item label="活动展示项目" prop="projectIdList">
                    <a-select :dropdownStyle="{ zIndex: '10004' }"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple"
                        v-model="form.projectIdList" :maxTagCount="7" :maxTagTextLength="5" style="width:800px"
                        showArrow placeholder="请选择适用项目" @change="handleChange">
                        <a-select-option v-for="item in ProjectList" :key="item.projectId">
                            {{ item.projectName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="活动类型" prop="activityTypeId">
                    <a-select :dropdownStyle="{ zIndex: '10004' }"
                        :getPopupContainer="(triggerNode) => triggerNode.parentNode" v-model="form.activityTypeId"
                        style="width: 800px;z-index: 10002;" placeholder="请选择活动类型">
                        <a-select-option v-for="item in activityTypeList" :key="item.activityTypeId"
                            :value="item.activityTypeId">
                            {{ item.activityTypeName }}
                        </a-select-option>
                    </a-select>
                </a-form-model-item>
                <a-form-model-item label="活动周期" prop="activityPeriod">
                    <a-radio-group :disabled="form.isnoEdit==0" v-model="form.activityPeriod">
                        <a-radio :value="'0'">单次活动</a-radio>
                        <a-radio :value="'1'">周期性活动{{  }}</a-radio>
                    </a-radio-group>
                    <br>
                    <div class="displayFlex" v-if="form.activityPeriod==1&&form.activeList.length<10" style="width: 800px;">
                        <a-button @click="activeBut" type="primary">
                            添加活动时间
                        </a-button>
                    </div>
                </a-form-model-item>
                <a-form-model-item :labelCol="{ style: 'width: 0px' }" :wrapper-col="{ span: 24 }">
                    <div v-for="(item, index) in form.activeList"   style="margin-top: 10px;display: flex;width: 1200px;" :key="index">
                        <Activity  ref="Activity" style="margin-top: 10px;width: 920px;"  :projectList="ProjectList" :index="index"  :formData="item" >
                        </Activity>
                        <a-button type="primary" :disabled="item.isnoEdit==0" @click="deleteActiveTime(index)" v-if="index != 0" style="margin-left: 30px;margin-top: 10px;"> 删除 </a-button>
                    </div>
                </a-form-model-item>

                <!-- <a-form-model-item label="活动时间" prop="endTime">
                    <a-range-picker :getCalendarContainer="(triggerNode) => {
                        return triggerNode.parentNode;
                    }
                        " :disabled-date="disabledDate" :show-time="{
        hideDisabledOptions: true,
        defaultValue: [moment('00:00', 'HH:mm'), moment('11:59', 'HH:mm')],
        format: 'HH:mm'
    }" format="YYYY-MM-DD HH:mm"
                        :value="form.startTime ? [moment(form.startTime, 'YYYY-MM-DD HH:mm'), moment(form.endTime, 'YYYY-MM-DD HH:mm')] : []"
                        @change="onChangeWriteOff" />
                </a-form-model-item> -->
                <a-form-model-item label="活动详情" :span="24" prop="activityDetail">
                    <div class="content" style="z-index: 0;">
                        <div id="editer" style="width: 800px;z-index: 0;"></div>
                    </div>
                </a-form-model-item>
                <a-form-model-item label="活动地址" prop="address">
                    <div>
                        <a-textarea style="width: 800px" v-model="form.address" :max-length="50" placeholder="请输入活动地址"
                            :auto-size="{ minRows: 3, maxRows: 5 }" />
                        <p style="text-align: right;">{{ form.address.length }}/50</p>
                    </div>
                </a-form-model-item>
            </a-form-model>
            <a-form-model v-show="current === 1" ref="ruleForm1" :model="form1" :labelCol="{ style: 'width: 150px' }"
                labelAlign="right" :rules="rules">
                <a-form-model-item label="活动是否可以分享" prop="isShare">
                    <a-radio-group style="margin-right: 10px;" v-model="form1.isShare">
                        <a-radio :value="1">
                            否
                        </a-radio>
                        <a-radio :value="0">
                            是
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item label="是否需要报名" prop="isApply">
                    <a-radio-group v-model="form1.isApply" @change="onChangeIsApply">
                        <a-radio :value="1">
                            否
                        </a-radio>
                        <a-radio :value="0">
                            是
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <template v-if="form1.isApply === 1">
                    <a-form-model-item label="是否展示无需报名" prop="isShowNeedNotRegister">
                        <a-radio-group v-model="form1.isShowNeedNotRegister">
                            <a-radio :value="1">
                                是
                            </a-radio>
                            <a-radio :value="0">
                                否
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                </template>
                <template v-if="form1.isApply === 0">
                    <a-form-model-item label="活动用户限制" prop="userRestriction">
                        <a-radio-group v-model="form1.userRestriction" @change="onChangeUserRestriction">
                            <a-radio :value="0">
                                无限制
                            </a-radio>
                            <a-radio :value="4">
                                实名认证用户
                            </a-radio>
                            <a-radio :value="1">
                                项目所有用户
                            </a-radio>
                            <a-radio :value="2">
                                项目下指定企业类型用户
                            </a-radio>
                            <a-radio :value="3">
                                指定用户名单
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item v-if="form1.userRestriction === 2" label="适用企业类型" prop="enterpriseTypeIdIdList">
                        <a-select :getPopupContainer="(triggerNode) => triggerNode.parentNode" mode="multiple"
                            v-model="form1.enterpriseTypeIdIdList" :maxTagCount="7" :maxTagTextLength="5"
                            style="width:800px" showArrow placeholder="请选择适用企业类型">
                            <a-select-option v-for="item in enterpriseTypeIdIdList" :key="item.enterprise_type_id">
                                {{ item.enterprise_type_name }}
                            </a-select-option>
                        </a-select>
                    </a-form-model-item>
                    <a-form-model-item v-if="form1.userRestriction === 3" label="指定用户名单" prop="fileName">
                        <div style="display: flex;">
                            <!-- <a-upload :default-file-list="activityxlsfileList" :fileList="activityxlsfileList" 
                                @change="handleChange1"> -->
                            <div style="position: relative;">
                                <input ref="file" type="file"
                                    accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel "
                                    @change="handleChange1($event)"
                                    style="width: 98px;position: absolute;height: 32px;z-index: 10;opacity: 0;">
                                <a-button type="primary"> + 点击上传 </a-button>
                            </div>
                            <!-- </a-upload> -->
                            <a-button style="margin-left:20px" @click="GET_TEMPLATE">下载模板</a-button>
                        </div>
                        <div v-if="form1.fileName" class="delfile">
                            <span style="margin-right: 10px;cursor: pointer;" @click="GET_USER_LIST"> {{ form1.fileName
                                }}</span>
                            <a-icon type="delete" @click="delfile()" />
                        </div>
                    </a-form-model-item>
                    <a-form-model-item v-if="form1.userRestriction != 3" label="报名人数限制" prop="personnelCeilingType">
                        <a-input-number v-model="form1.personnelCeiling" :disabled="form1.personnelCeilingType == 2"
                            @blur="onChangePersonnelCeiling" style="margin-right: 10px;" />
                        <a-checkbox v-model="personnelCeilingType" @change="onChangepersonnelCeilingType">
                            无限制
                        </a-checkbox>
                        <p v-if="title == '编辑活动'">当前已报名人数：{{ form1.count }}，编辑数量时不可小于此人数</p>
                    </a-form-model-item>
                    <a-form-model-item label="选择报名信息模板">
                        <div style="display: flex;">
                            <div class="btn" @click="applyTemplate(1)"
                                v-if="form1.userRestriction != 4 || form1.userRestriction == 0"
                                :class="[form1.applyTemplate === 1 ? 'btn_c' : '']">
                                模板一</div>
                            <div class="btn" @click="applyTemplate(2)"
                                v-if="form1.userRestriction != 4 || form1.userRestriction == 0"
                                :class="[form1.applyTemplate === 2 ? 'btn_c' : '']">
                                模板二</div>
                            <div class="btn" @click="applyTemplate(3)"
                                v-if="form1.userRestriction == 4 || form1.userRestriction == 0"
                                :class="[form1.applyTemplate === 3 ? 'btn_c' : '']">
                                模板三</div>
                            <div class="btn" @click="applyTemplate(4)"
                                v-if="form1.userRestriction == 4 || form1.userRestriction == 0"
                                :class="[form1.applyTemplate === 4 ? 'btn_c' : '']">
                                模板四</div>
                            <div class="btn" @click="applyTemplate(5)"
                                :class="[form1.applyTemplate === 5 ? 'btn_c' : '']">
                                模板五</div>
                        </div>
                        <p style="color:#ccc" v-if="form1.applyTemplate === 1">联系人姓名、联系人电话、企业名称、入驻项目、联系人职位（可选）</p>
                        <p style="color:#ccc" v-else-if="form1.applyTemplate === 2">
                            联系人姓名、联系人电话、企业名称、入驻项目、联系人职位（可选）、企业遇到的问题（可选）</p>
                        <p style="color:#ccc" v-else-if="form1.applyTemplate === 4">联系人姓名、联系人电话、企业/社区名称、服务意向、备注（可选）</p>
                        <p style="color:#ccc" v-else-if="form1.applyTemplate === 5">联系人姓名、联系人电话、地址</p>
                        <p style="color:#ccc" v-else>联系人姓名、联系人电话</p>
                    </a-form-model-item>
                    <a-form-model-item label="报名签到方式" prop="participationWay">
                        <a-radio-group v-model="form1.participationWay" @change="onChange">
                            <a-radio :value="0">
                                线下签到
                            </a-radio>
                            <a-radio :value="1">
                                无需签到
                            </a-radio>
                            <a-radio :value="2">
                                线上签到
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item label="活动开始通知" prop="isNotification">
                        <a-radio-group v-model="form1.isNotification" @change="onChange">
                            <a-radio :value="1">
                                关闭
                            </a-radio>
                            <a-radio :value="0">
                                开启
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item v-if="form1.isNotification === 0" label="消息通知方式" prop="notificationType">
                        <a-checkbox-group v-model="form1.notificationType">
                            <a-row>
                                <a-checkbox value="0">
                                    短信通知
                                </a-checkbox>
                                <a-checkbox value="1">
                                    App通知
                                </a-checkbox>
                            </a-row>
                        </a-checkbox-group>
                    </a-form-model-item>
                    <a-form-model-item v-if="form1.isNotification === 0" label="消息推送时间" prop="messagePushTime">
                        活动开始前
                        <a-checkbox-group v-model="form1.messagePushTime">
                            <a-row>
                                <a-checkbox value="5">
                                    5分钟
                                </a-checkbox>
                                <a-checkbox value="2">
                                    2小时
                                </a-checkbox>
                                <a-checkbox value="12">
                                    12小时
                                </a-checkbox>
                                <a-checkbox value="24">
                                    24小时
                                </a-checkbox>
                            </a-row>
                        </a-checkbox-group>
                    </a-form-model-item>

                    <a-form-model-item label="报名后活动详细地址" prop="viewDetailAddress">
                        <a-radio-group v-model="form1.viewDetailAddress" @change="onChange">
                            <a-radio :value="1">
                                关闭
                            </a-radio>
                            <a-radio :value="0">
                                开启
                            </a-radio>
                        </a-radio-group>
                    </a-form-model-item>
                    <a-form-model-item v-if="form1.viewDetailAddress === 0" label="活动详细地址" prop="detailAddress">
                        <div>
                            <a-textarea style="width: 800px" v-model="form1.detailAddress" :max-length="50"
                                placeholder="请输入活动详细地址" :auto-size="{ minRows: 3, maxRows: 5 }" />
                            <p style="text-align: right;">{{ form1.detailAddress ? form1.detailAddress.length : '0'
                                }}/50
                            </p>
                        </div>
                    </a-form-model-item>
                </template>

            </a-form-model>
            <a-form-model v-show="current === 2" ref="ruleForm2" :model="form2" :labelCol="{ style: 'width: 120px' }"
                labelAlign="right" :rules="rules">
                <a-form-model-item label="是否发放问卷" prop="isQuestionnaire">
                    <a-radio-group :disabled="form1.isApply ? true : false" v-model="form2.isQuestionnaire"
                        @change="onChange">
                        <a-radio :value="1">
                            否
                        </a-radio>
                        <a-radio :value="0">
                            是
                        </a-radio>
                    </a-radio-group>
                </a-form-model-item>
                <a-form-model-item v-if="form2.isQuestionnaire === 0" label="问卷选择" prop="questionnaireName">
                    <div>{{ form2.questionnaireName ? form2.questionnaireName : '请选择问卷' }} <a-button type="primary"
                            @click="visible = true">{{ form2.questionnaireName ? '重新选择' : '选择' }}</a-button></div>
                </a-form-model-item>
            </a-form-model>
        </div>

        <div class="btn_box">
            <div>
                <a-button v-if="current == 0" style="margin-right:20px" type="primary"
                    @click="showConfirm">返回</a-button>
                <a-button v-else style="margin-right:20px" type="primary" @click="() => current += -1">上一步</a-button>
                <a-button v-if="current !== 2" type="dashed" @click="submit">下一步</a-button>
                <a-button v-if="current == 2" style="margin-right:20px" type="dashed" @click="ok">完成</a-button>
            </div>
        </div>
        <a-modal dialogClass="dialogClass" v-model="visible" title="选择问卷" ok-text="确认" cancel-text="取消" @ok="hideModal">
            <div style="display: flex;justify-content: space-between;">
                <a-input style="width: 200px;margin-bottom: 20px;" v-model.trim="questionnaireName"
                    placeholder="请输入问卷名称查询"></a-input>
                <a-button type="primary" @click="search" style="margin-top: 3px" icon="search"> 查询 </a-button>
            </div>

            <a-table :row-selection="{ type: 'radio', onChange: selectedPosition, selectedRowKeys: selectedRowKeys }"
                :rowKey="(record, index) => record.questionnaireId" :columns="table.columns"
                :data-source="table.tableData" :pagination="pagination.total ? pagination : false" @change="pageChange"
                bordered :scroll="{ x: 1500 }">

            </a-table>
        </a-modal>
        <a-modal dialogClass="dialogClass" :destroyOnClose="true" :zIndex="10004" :confirm-loading="loading"
            :maskClosable="false" title="上传活动封面" :width="800"
            @cancel="visible2 = false; thumbUrl = ''; img = ''; loading = false" @ok="uploadjietu" :visible="visible2">
            <a-spin :spinning="spinning">
                <div style="width: 500px;height: 550px;">
                    <div style="display: flex;align-items: end;margin-bottom: 20px;cursor:pointer;">
                        <div style="width: 104px;position: relative;cursor:pointer">
                            <a-button style="width: 100%;position: relative;">
                                {{ thumbUrl ? '更换' : '上传' }}图片
                                <input
                                    style="cursor:pointer;height: 100%;width: 100%;position: absolute;opacity: 0;z-index: 1000;left: 0;top: 0;"
                                    type="file" ref="file" @change="fileChange" accept="image/*">
                            </a-button>
                            <!-- <a-icon @click="uploadjietu" style="font-size:14px;position: absolute;left: 50%;top: 50% ;transform: translate(-50%,-50%);" type="plus" /> -->
                        </div>
                    </div>
                    <cropper style="width: 500px;height: 500px;" class="cropper" ref="cropper" :src="thumbUrl"
                        :stencil-props="{
                            aspectRatio: 3 / 2
                        }" />
                    <!-- <vueCropper v-show="thumbUrl" ref="cropper" :zIndex="444444"
                    style="width: 400px;height: 400px;"
                    :centerBox="true" 
                    :fixed='true' 
                    :img="thumbUrl" 
                    :autoCrop="true"
                    :fixedNumber="[3, 2]" 
                    :outputSize="option.size" 
                    :full="true"
                    :infoTrue="true"
                    :enlarge="2"
                    mode="contain"
                    :outputType="option.outputType">
                    上传图片
                </vueCropper> -->
                </div>
            </a-spin>
        </a-modal>
    </div>
</template>
<script>
import { getEnterpriseType, UPDATE_CALENDER_ACTIVITY, importUserFile, GET_USER_LIST, GET_TEMPLATE, GET_LISTA_CTIVITYLIST, FINDPROJECT, FIND_ENTERPRIES_TYPE, GET_PLATPORM_QUESTIONNAIRE, GET_LIST_MOD, INSET_ACTIVITY ,updataJietu} from "../../../api/enterpriseServiceActivitiesManagement"
import { IMG_API } from "@/config";
import moment from "moment";
import wangEditor from "wangeditor";
import { Cropper } from 'vue-advanced-cropper';
import 'vue-advanced-cropper/dist/style.css';
import Activity from './components/activity.vue'
export default {
    components: {
		Cropper,
        Activity
	},
    data() {
        return {
            visible2:false,
            disabled: false,
            title: '新建活动',
            visible: false,
            current: 0,//步骤条
            IMG_API: IMG_API,
            form: {
                activityTitle: '',
                activityImage: "",
                activityTypeId: "",
                activityTypeName: "",
                startTime: '',
                endTime: '',
                address: '',
                activityDetail: '',
                projectIdList: [],
                activityPeriod:'0',
                activeList:[{maintenance:[{time:'',checkList:[],sessionList:[]}],dateList:[],projectId: undefined, siteId: undefined, maintenanceTimeList: []}],//维护活动
            },
            defaultValue: [],//默认时间
            form1: {
                userRestriction: 0,
                enterpriseTypeIdIdList: [],
                isApply: 1,
                participationWay: 0,
                isNotification: 1,
                notificationType: [],
                messagePushTime: [],
                isShare: 1,
                detailAddress: '',
                viewDetailAddress: 1,
                applyTemplate: 1,
                activityPersonnelIdList: [],
                fileName: '',//文件名
                isShowNeedNotRegister:1,//是否需要展示无需报名
                personnelCeilingType:0,//人数上限类型 0无 1:有限制 2:无限制
                personnelCeiling:'',// 报名总数
                count:0,// 已报名人数

            },
            form2: {
                isQuestionnaire: 1,
                questionnaireId: '',
                questionnaireName: '',
            },
            questionnaireName: '',
            questionnaire: {},
            selectedRowKeys: [],
            platformQuestionnaireList: [],//问题列表
            ProjectList: [],
            enterpriseTypeIdIdList: [],
            enterpriseTypeId: {},
            activityTypeList: [],
            spPicLoading: false,
            // 专场图片
            activityImagefileList: [],
            activityxlsfileList: [],
            rules: {
                activityTitle: [{ required: true, message: "此为必填项", trigger: "blur" }],
                activityImage: [{ required: true, message: "此为必填项", trigger: ["change","click"] }],
                activityTypeId: [{ required: true, message: "此为必填项", trigger: "change" }],
                address: [{ required: true, message: "此为必填项", trigger: "change" }],
                detailAddress: [{ required: true, message: "此为必填项", trigger: "change" }],
                activityDetail: [{ required: true, message: "此为必填项", trigger: "change" }],
                startTime: [{ required: true, message: "此为必填项", trigger: "change" }],
                endTime: [{ required: true, message: "此为必填项", trigger: "change" }],
                projectIdList: [{ required: true, message: "此为必填项", trigger: "change" }],
                userRestriction: [{ required: true, message: "此为必填项", trigger: "change" }],
                enterpriseTypeIdIdList: [{ required: true, message: "此为必填项", trigger: "change" }],
                fileL: [{ required: true, message: "此为必填项", trigger: "change" }],
                isApply: [{ required: true, message: "此为必填项", trigger: "change" }],
                participationWay: [{ required: true, message: "此为必填项", trigger: "change" }],
                isNotification: [{ required: true, message: "此为必填项", trigger: "change" }],
                notificationType: [{ required: true, message: "此为必填项", trigger: "change" }],
                messagePushTime: [{ required: true, message: "此为必填项", trigger: "change" }],
                isShare: [{ required: true, message: "此为必填项", trigger: "change" }],
                isQuestionnaire: [{ required: true, message: "此为必填项", trigger: "change" }],
                viewDetailAddress: [{ required: true, message: "此为必填项", trigger: "change" }],
                questionnaireName: [{ required: true, message: "此为必填项", trigger: ["change", 'click'] }],
                fileName: [{ required: true, message: "此为必填项", trigger: "change" }],
                isShowNeedNotRegister: [{ required: true, message: "此为必填项", trigger: "change" }],
                personnelCeilingType: [{ required: true, message: "此为必填项", trigger: "change" }],
                activityPeriod: [{ required: true, message: "此为必填项", trigger: "change" }],
            },
            specialPictureVisible: false,
            pagination: {
                pageSizeOptions: ["10", "20", "30", "50", "100"],
                showSizeChanger: true,
                current: 1,
                pageSize: 10,
                total: 0,
                showQuickJumper: true,
                showTotal: (total) => {
                    return `共 ${total} 条`;
                },
            },
            table: {
                columns: [
                    {
                        title: "问卷名称",
                        dataIndex: "questionnaireName",
                        key: "questionnaireName",
                        width: 150,
                    },
                    {
                        title: "创建人",
                        dataIndex: "createBy",
                        key: "createBy",
                        width: 160,
                    },
                    {
                        title: "创建时间",
                        dataIndex: "publishStartTime",
                        key: "publishStartTime",
                        width: 187,
                    },
                ],
                tableData: [],
                pagination: {
                    pageSizeOptions: ["10", "20", "30", "50", "100"],
                    showSizeChanger: true,
                    current: 1,
                    pageSize: 10,
                    total: 0,
                    showQuickJumper: true,
                    showTotal: (total) => {
                        return `共 ${total} 条`;
                    },
                },
            },
            createBy: '',//问卷创建人
            count: 0,// 报名人数
            option:{
                img:'',
                size:1,
                outputType:'jpg',
            },
            thumbUrl:'',
            file:'',
            loading:false,
            spinning:false,
            maxNum:999999,// 报名最大人数限制
            minNum:0,// 报名最小人数限制
            personnelCeilingType:false,
            flag:false,
            activityAddData:[], //活动维护模块数据
        }
    },
    created() {
        this.GET_LISTA_CTIVITYLIST()
        this.FINDPROJECT()
        this.FIND_ENTERPRIES_TYPE()
        this.GET_PLATPORM_QUESTIONNAIRE()
        if (this.$route.query.id) {
            this.GET_LIST_MOD()
            this.title = '编辑活动'
            this.disabled = true
        }
    },
    mounted() {
        const _this = this;
        const editor = new wangEditor(`#editer`);
        // 配置 onchange 回调函数，将数据同步到 vue 中
        editor.config.onchange = (newHtml) => {
            this.form.activityDetail = newHtml;
        };
        editor.config.menus = [
            "fontSize",
            "foreColor",
            "backColor",
            "underline",
            "italic",
            "bold",
            "justify",
            "splitLine",
            "undo",
            "redo",
            "list",
            "table",
            "image",
            'video',
        ];
        editor.config.showFullScreen = false;
        editor.config.uploadVideoServer = this.IMG_API + "/oss/files";
        editor.config.uploadImgServer = this.IMG_API + "/oss/files";
        editor.config.uploadImgShowBase64 = false;
        editor.config.showLinkImg = false;
        editor.config.showLinkVideo = false;
        editor.config.uploadVideoMaxSize = 5 * 1024 * 1024;
        editor.config.uploadImgMaxSize = 5 * 1024 * 1024;
        editor.config.uploadImgAccept = ["jpg", "jpeg", "png", "gif"];
        editor.config.uploadImgMaxLength = 1; // 一次最多上传 1 个图
        // editor.config.uploadVideoAccept = ['mp4']
        editor.config.uploadFileName = "file";
        editor.config.uploadVideoName = "file";
        editor.config.uploadVideoTimeout = 60000;
        editor.config.uploadImgTimeout = 60000;
        editor.config.uploadVideoHooks = {
            // 上传视频之前
            before: function (xhr, editor, files) {
                console.log(xhr);
                console.log(editor);
                console.log(files);
            },
            // 视频上传并返回了结果，视频插入已成功
            success: function (xhr) {
                // this.$message.success('上传视频成功')
            },
            // 视频上传并返回了结果，但视频插入时出错了
            fail: function (xhr, editor, resData) {
                _this.$message.error("上传请求失败");
            },
            // 上传视频出错，一般为 http 请求的错误
            error: function (xhr, editor, resData) {
                _this.$message.error("上传请求失败");
            },
            // 上传视频超时
            timeout: function (xhr) {
                _this.$message.error("上传请求超时");
            },
            // 视频上传并返回了结果，想要自己把视频插入到编辑器中
            // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
            customInsert: function (insertVideoFn, result) {
                let videoHTML =
                    '&nbsp;<video poster="' +
                    result.redirect_uri +
                    '?x-oss-process=video/snapshot,t_0,f_jpg,w_0,h_0,m_fast,ar_auto" src="' +
                    result.redirect_uri +
                    '" controls style="max-width:100%"></video>&nbsp;';
                editor.cmd.do("insertHTML", videoHTML);
                // result 即服务端返回的接口
                // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
                // insertVideoFn(result.redirect_uri)
            },
        };
        editor.config.uploadImgHooks = {
            // 上传视频之前
            before: function (xhr) { },
            // 视频上传并返回了结果，视频插入已成功
            success: function (xhr) {
                // this.$message.success('上传视频成功')
            },
            // 视频上传并返回了结果，但视频插入时出错了
            fail: function (xhr, editor, resData) {
                _this.$message.error("上传请求失败");
            },
            // 上传视频出错，一般为 http 请求的错误
            error: function (xhr, editor, resData) {
                _this.$message.error("上传请求失败");
            },
            // 上传视频超时
            timeout: function (xhr) {
                _this.$message.error("上传请求超时");
            },
            // 视频上传并返回了结果，想要自己把视频插入到编辑器中
            // 例如服务器端返回的不是 { errno: 0, data: { url : '.....'} } 这种格式，可使用 customInsert
            customInsert: function (insertVideoFn, result) {
                // result 即服务端返回的接口
                // insertVideoFn 可把视频插入到编辑器，传入视频 src ，执行函数即可
                insertVideoFn(result.redirect_uri);
            },
        };
        editor.config.customAlert = function (s, t) {
            switch (t) {
                case "success":
                    _this.$message.success(s);
                    break;
                case "info":
                    _this.$message.info(s);
                    break;
                case "warning":
                    _this.$message.warning(s);
                    break;
                case "error":
                    _this.$message.error(s);
                    break;
                default:
                    _this.$message.info(s);
                    break;
            }
        };
        editor.config.placeholder = "请输入活动详情";
        // 创建编辑器
        editor.create();
        this.editor = editor;
    },
    methods: {
        // 点击返回
        showConfirm() {
            let that = this
            this.$confirm({
                title: '确认要返回吗?',
                content: that.$route.query.id ? '' : '返回列表将丢失所有数据',
                zIndex: 100000,
                onOk() {
                    that.$router.go(-1)
                },
                onCancel() { },
            });
        },
        async specialPicturePreview(file) {
            console.log(file);
            if (!file.url && !file.preview) {
                file.preview = await getBase64(file.originFileObj);
            }
            this.specialPictureVisible = true;
        },
        delfile() {
            console.log(this.$refs.file);
            this.form1.fileName = ''
            this.$refs.file.value = null
        },
        beforespecialPictureUpload(file) {
            // 校验格式
            console.log(file);
            let that = this
            const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png" || file.type === "image/jpg";
            if (!isJpgOrPng) {
                this.$message.error("请上传jpg、png格式");
                return Promise.reject();
            }
            // 校验大小
            const isLt5M = file.size / 1024 / 1024 <= 10;
            if (!isLt5M) {
                this.$message.error("上传图片最大10M");
                return Promise.reject();
            }
            const isSize = new Promise(function (resolve, reject) {
                const URL = window.URL || window.webkitURL;
                const img = new Image();
                img.onload = function () {
                    const proportion = that.reductionTo(img.width, img.height);
                    const valid = proportion[0] === 3 && proportion[1] === 2
                    valid ? resolve() : reject();
                };
                img.src = URL.createObjectURL(file);
            }).then(
                () => {
                    return file;
                },
                () => {
                    that.$message.error("图片不规范，请按要求上传");
                    return reject();
                }
            );
            console.log(isJpgOrPng && isLt5M);
            return isJpgOrPng && isLt5M && isSize;
        },
        //计算图片比例
        reductionTo(m, n) {
            let arr = [];
            let a = m;
            let b = n;
            (a >= b) ? (a = m, b = n) : (a = n, b = m);
            if (m != 1 && n != 1) {
                for (let i = b; i >= 2; i--) {
                    if (m % i == 0 && n % i == 0) {
                        m = m / i;
                        n = n / i;
                    }
                }
            }
            arr[0] = m;
            arr[1] = n;
            return arr;
        },
        handleSpPicChange(info) {
            console.log(info);
            this.activityImagefileList = info.fileList
            if (info.file.status === "uploading") {
                this.loading = true;
                return;
            }
            if (info.file.status === "removed") {
                this.form.activityImage = '';
                this.activityImagefileList = []
                return;
            }
            if (info.file.status === "done") {
                this.loading = false;
                this.form.activityImage = info.file.response.redirect_uri;
                this.activityImagefileList = [{
                    uid: info.file.uid,
                    name: info.file.name,
                    status: 'done',
                    url: info.file.response.redirect_uri
                }]
            }
        },
        // 获取活动类型
        async GET_LISTA_CTIVITYLIST() {
            let data = await GET_LISTA_CTIVITYLIST({projectIdList:this.form.projectIdList})
            if (data.code === '200') {
                this.activityTypeList = data.data
            }
        },
        // 获取适用项目
        async FINDPROJECT() {
            let data = await FINDPROJECT();
            this.ProjectList = data.data;
        },
        moment,
        range(start, end) {
            const result = [];
            for (let i = start; i < end; i++) {
                result.push(i);
            }
            return result;
        },
        // 固定时间
        // onChangeWriteOff(date, dateString) {
        //     this.form.startTime =dateString[0]? dateString[0] + ':00':'';
        //     this.form.endTime =dateString[1]? dateString[1] + ':00':'';

        //     console.log(date, dateString)
        // },
        // 限制不可以选择今天之前的时间
        disabledDate(current) {
            return current && current < moment().subtract(1, "days").endOf("day");
        },
        submit() {
            if (this.current === 0) {
                this.$refs.ruleForm.validate(async (valid) => {
                    console.log(valid);
                    if (valid) {
                        let ActivityAddType = true
                        let ActivityAddData = []
                        this.$refs.Activity.forEach(item => {
                            if (!item.checkForm()) {
                                ActivityAddType = false
                            } else {
                                ActivityAddData.push(item.checkForm())
                            }
                        })
                        this.activityAddData=ActivityAddData
                        if(!ActivityAddType){
                            return
                        }
                        if(this.form.activityPeriod=='0'&&ActivityAddData.length!=1){
                            this.$message.error('请删除一条活动')
                            return
                        }
                        this.current += 1

                    } else {
                    }
                });
                
                
            } else if (this.current === 1) {
                this.$refs.ruleForm1.validate(async (valid) => {
                    if(this.flag)return
                    if(this.form1.personnelCeilingType == 1 && this.form1.userRestriction !=3){
                        if(!this.form1.personnelCeiling){
                            this.$message.error('请输入报名人数上限');
                            return
                        }
                    }
                    console.log(valid);
                    if (valid) {
                        this.current += 1

                    } else {
                    }
                });
            }
            console.log(this.form1, this.form);
        },
        onChange(e) {
            console.log('radio checked', this.form1);
        },
        onChangeUserRestriction(e){
            if( this.form1.userRestriction ==4){
                this.form1.applyTemplate = 3
            }else{
                this.form1.applyTemplate = 1
            }
            if(this.form1.userRestriction ==3){
                this.form1.personnelCeilingType =0;
            }else{
                this.form1.personnelCeilingType =1;
            }
            this.form1.personnelCeiling ='';
        },
        handleChange(value) {
            console.log(this.form1.projectIdList);
            this.FIND_ENTERPRIES_TYPE()
            this.GET_LISTA_CTIVITYLIST()
            this.form.activityTypeId = ''
        },
        // 获取适用公司
        // async FIND_ENTERPRIES_TYPE() {
        //     let data = await FIND_ENTERPRIES_TYPE(this.form1.projectIdList)
        //     this.enterpriseTypeIdIdList = Object.keys(data.data)
        //     this.enterpriseTypeId = data.data
        //     console.log( this.enterpriseTypeIdIdList , this.enterpriseTypeIdId);

        // },
        async FIND_ENTERPRIES_TYPE() {
            let data = await getEnterpriseType()
            console.log(data);
            if (data.data.code === '200') {
                this.enterpriseTypeIdIdList = data.data.data
                // this.enterpriseTypeIdList = data.data.data

            }
        },
        // 获取问卷列表
        async GET_PLATPORM_QUESTIONNAIRE() {
            const dataform = {
                page: this.pagination.current,
                size: this.pagination.pageSize,
                createBy: this.createBy,
                questionnaireName: this.questionnaireName
            };
            let data = await GET_PLATPORM_QUESTIONNAIRE(dataform)
            console.log(data);
            if (data.code === '200') {
                this.pagination.total = data.data.platformQuestionnaireCount
                this.table.tableData = data.data.platformQuestionnaireList
            }

        },
        // 切换分页
        pageChange(option) {
            this.pagination.current = option.current;
            this.pagination.pageSize = option.pageSize;
            this.GET_PLATPORM_QUESTIONNAIRE();
        },
        async handleChange1(file) {
            console.log(file.target.value);
            let filedate = file.target.files[0]
            console.log(file.target.files[0]);
            this.form1.fileName = file.target.files[0].name
            const formData = new FormData();
            formData.append('file', filedate);
            if (this.$route.query.id) {
                formData.append('activityId', this.$route.query.id);
            }

            importUserFile(formData).then(res => {
                console.log(res, '+++');
                if (res.code === '200') {
                    this.form1.activityPersonnelIdList = res.data
                } else {
                    this.form1.fileName = ''
                    this.$refs.file.value = null
                    this.$message.error('上传失败，请检查表格信息')
                }
            }).catch(res => {
                this.form1.fileName = ''
                this.$refs.file.value = null
                this.$message.error('上传失败，请检查表格信息')
                console.log(res);
            })
            // return
            // let res = await importUserFile(formData)
            // if (res.code === '200') {
            //     this.form1.activityPersonnelIdList = res.data
            // } else {
            //     this.form1.fileName = ''
            //     this.$refs.file.value = null
            //     this.$message.error('上传失败')
            // }

        },
        // 选择模板
        applyTemplate(val) {
            // if (this.$route.query.id) return
            this.form1.applyTemplate = val
        },
        // 获取详情
        async GET_LIST_MOD() {
            let res = await GET_LIST_MOD({ activityId: this.$route.query.id })
            for (const key in this.form) {
                this.form[key] = res.data[key]
            }
            for (const key in this.form1) {
                this.form1[key] = res.data[key]
                if(this.form1.personnelCeilingType ==2){
                    this.personnelCeilingType = true
                }
            }
            if (res.data.isNotification !== 1) {
                this.form1.notificationType = res.data.notificationType.split(',')
                this.form1.messagePushTime = res.data.messagePushTime.split(',')
            }

            for (const key in this.form2) {
                this.form2[key] = res.data[key]
            }
            this.count = res.data.count
            // this.FIND_ENTERPRIES_TYPE(res.data.projectIdList)
            this.activityImagefileList = [{
                uid: '-1',
                name: 'xx',
                status: 'done', url: res.data.activityImage
            }]
            this.activityxlsfileList = [{
                uid: '-2',
                name: res.data.fileName,
                status: 'done',
                url: ''
            }]
           
            this.form.activityPeriod=res.data.activityPeriod.toString()
            
            if(res.data.activityPeriods){
                let list=[]
                res.data.activityPeriods.forEach(item=>{
                let obj={
                    dateList:[moment(item.startTime),moment(item.endTime)],
                    projectId:item.projectId,
                    siteId:item.siteId,
                    notice:item.notice,
                    radio:item.publishType==0?1:2,
                    days:item.publishType!=0?item.publishType:'',
                    maintenance:[],
                    activityId:item.id,
                    isnoEdit:item.isnoEdit
                    // {time:'',checkList:[],sessionList:[]}
                }
                if(item.maintainTime){
                    let timeList=item.maintainTime.split(' ')
                    obj.maintenance.push({time:timeList[0],checkList:timeList[1].split('、')})
                }
                list.push(obj)
                })
                this.form.activeList=list
                this.$nextTick(()=>{
                this.$refs.Activity.forEach(item => {
                    item.childProcessingChange()         
                    })
                })
            }else{
                this.form.activeList=[{maintenance:[{time:'',checkList:[],sessionList:[]}],dateList:[],projectId: undefined, siteId: undefined, maintenanceTimeList: []}]
            }
            this.form.dataType=res.data.dataType
            this.form.isnoEdit=res.data.isnoEdit
            this.editor.txt.html(res.data.activityDetail)
            this.GET_LISTA_CTIVITYLIST()
        },
        hideModal() {
            this.visible = false;
            this.form2.questionnaireId = this.questionnaire.questionnaireId
            this.form2.questionnaireName = this.questionnaire.questionnaireName
        },
        selectedPosition(items, selectedRows) {
            this.selectedRowKeys = items
            this.questionnaire = selectedRows[0]
        },
        search() {
            this.GET_PLATPORM_QUESTIONNAIRE()
        },
        params() {
            let form=JSON.parse(JSON.stringify(this.form))
            // let data = {
            //     ...this.form,
            //     ...this.form1,
            //     ...this.form2,
            //     activityPeriods:this.activityAddData
            // }
            delete form.activeList
            let data = {
                ...form,
                ...this.form1,
                ...this.form2,
                activityPeriods:this.activityAddData,
                activityId:this.$route.query.id
            }
            if(!this.form1.messagePushTime){
                data.messagePushTime=''
            }else{
                data.messagePushTime=this.form1.messagePushTime.join(',')
            }
            if(!this.form1.notificationType){
                data.notificationType=''
            }else{
                data.notificationType=this.form1.notificationType.join(',')
            }
            // const formData = new FormData();
            // for (const key in data) {
            //     if(key == 'personnelCeiling' && !data.personnelCeiling){

            //     }else{
            //         formData.append(key, data[key]);
            //     }
            //     console.log(formData.get(key));
            // }
            // if (this.$route.query.id) {
            //     formData.append('activityId', this.$route.query.id);
            // }
            // console.log(formData);
            
            return data
        },
        ok() {
            let that = this
            if (this.form2.isQuestionnaire === 0) {
                this.$refs.ruleForm2.validate(async (valid) => {
                    console.log(valid);
                    if (valid) {
                        if (this.$route.query.id) {
                            if (this.count > 0) {
                                this.$confirm({
                                    title: '确定要编辑吗？',
                                    content: `此活动已有${that.count}报名，活动编辑后将会对已报名的用户发送APP通知`,
                                    zIndex: 100000,
                                    onOk() {
                                        that.UPDATE_CALENDER_ACTIVITY()
                                    },
                                    onCancel() { },
                                });
                            } else {
                                that.UPDATE_CALENDER_ACTIVITY()
                            }

                        } else {
                            this.INSET_ACTIVITY()
                        }
                    } else {
                        return
                    }
                });
            } else {
                if (this.$route.query.id) {
                    if (this.count > 0) {
                        this.$confirm({
                            title: '确定要编辑吗？',
                            content: `此活动已有${that.count}报名，活动编辑后将会对已报名的用户发送APP通知`,
                            zIndex: 100000,
                            onOk() {
                                that.UPDATE_CALENDER_ACTIVITY()
                            },
                            onCancel() { },
                        });
                    } else {
                        that.UPDATE_CALENDER_ACTIVITY()
                    }

                } else {
                    this.INSET_ACTIVITY()
                }
            }

        },
        async INSET_ACTIVITY() {
            INSET_ACTIVITY(this.params()).then(res => {
                if (res.code === '200') {
                    this.$message.info('提交成功');
                    this.$router.go(-1)
                }
            }).catch(res => {
                this.$message.error('请求报错添加失败');
            })
            // let res = await INSET_ACTIVITY(this.params())
            // if (res.code === '200') {
            //     this.$message.info('提交成功');
            //     this.$router.go(-1)
            // } else {
            //     this.$message.error('请求报错添加失败');
            // }
        },
        async UPDATE_CALENDER_ACTIVITY() {
            console.log(this.params());
            UPDATE_CALENDER_ACTIVITY(this.params()).then(async res => {
                if (res.code === '200') {
                    this.$message.info('提交成功');
                    this.$router.go(-1)
                }else{
                    this.$message.error(res.msg)
                    let res = await GET_LIST_MOD({ activityId: this.$route.query.id })
                    this.form1.count = res.data.count
                }
            }).catch(async error => {
                this.$message.error('请求报错修改失败');
                let res = await GET_LIST_MOD({ activityId: this.$route.query.id })
                this.form1.count = res.data.count
            })
            // let res = await UPDATE_CALENDER_ACTIVITY(this.params())
            // if (res.code === '200') {
            //     this.$message.info('提交成功');
            //     this.$router.go(-1)
            // } else {
            //     this.$message.error('请求报错修改失败');
            // }
        },
        // 下载
        downloadCommon(res) {
            if ("msSaveOrOpenBlob" in navigator) {
                const url = res.data;
                const blob = new Blob([url], { type: "application/vnd.ms-excel" });
                let name = res.headers["content-disposition"].split("=")[1];
                window.navigator.msSaveOrOpenBlob(blob, decodeURI(name));
                return;
            } else {
                const url = window.URL.createObjectURL(new Blob([res.data]));
                const link = document.createElement("a");
                link.href = url;
                let name = res.headers["content-disposition"].split("=")[1];
                link.setAttribute("download", decodeURI(name));
                document.body.appendChild(link);
                link.click();
            }

        },
        // 下载模板
        async GET_TEMPLATE() {
            let res = await GET_TEMPLATE()
            console.log(res);
            this.downloadCommon(res)
        },
        // 下载模板
        async GET_USER_LIST() {
            if (this.$route.query.id) {
                let res = await GET_USER_LIST({ activityId: this.$route.query.id })
                console.log(res);
                this.downloadCommon(res)
            }

        },
        releaseCanvas(canvas) {
            canvas.width = 1;
            canvas.height = 1;
            const ctx = canvas.getContext('2d');
            ctx && ctx.clearRect(0, 0, 1, 1);
        },
        // 上传截图
        uploadjietu(){
          this.loading = true
          const {coordinates, image, visibleArea, canvas} = this.$refs.cropper.getResult();
          let newWidth = Math.floor(canvas.width / 3)*3;
          let newHeight= Math.floor(canvas.width / 3)*2;
          canvas.toBlob(blob=>{
            const file2 = new File([blob], 'fileName', { type: 'jpg', lastModified: Date.now() });
            const img = new Image();
            img.onload = async () =>{
                const { width, height } = img;
                const newWidth = Math.floor(width / 3)*3;
                const newHeight = Math.floor(width / 3)*2;
                console.log(newWidth,newHeight,'===>');
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx?.drawImage(img, 0, 0, newWidth, newHeight);
                const blobString = (await new Promise((resolve, reject) => {
                canvas.toBlob(async (blobFile) => {
                    const file2 = new File([blobFile], 'fileName', { type: 'jpg', lastModified: Date.now() });
                    const isLt5M = file2.size / 1024 / 1024 <= 10;
                    console.log(file2);
                    if (!isLt5M) {
                        this.$message.error("上传图片最大10M");
                        return
                    }
                    const formData = new FormData();
                    formData.append('file', file2);
                    updataJietu(this.IMG_API + "/oss/files",formData).then(res=>{
                        this.loading = false
                        this.form.activityImage = res.redirect_uri;
                        this.$refs.ruleForm.validateField('activityImage')
                        this.visible2 = false
                        this.thumbUrl = ''
                        this.activityImagefileList = [{
                            uid:res.id,
                            name: res.name,
                            status: 'done',
                            url: res.redirect_uri
                        }]
                        console.log(res,'===========>');
                    })
                    resolve(blobFile);
                    },'image/jpeg');
                }).catch((err) => {
                    throw new Error(err);
                }))
                // 释放canvas ，避免内存泄露
                this.releaseCanvas(canvas);
            }
            img.src = URL.createObjectURL(file2);
          },'image/jpeg')
          return
            var arr = this.img.split(','); //分割为数组，分割到第一个逗号
            let mime = arr[0].match(/:(.*?);/)[1];//获取分割后的base64前缀中的类型
            let bstr = window.atob(arr[1]); 
            let n = bstr.length;
            let u8arr = new Uint8Array(n);
            while (n--) {
                u8arr[n] = bstr.charCodeAt(n);
            }
            let data = new Blob([u8arr], { type: mime });
            // this.$refs.cropper.getCropBlob(data => {
              const file = new File([data], 'fileName', { type: 'jpg', lastModified: Date.now() });
              const img = new Image();
              img.onload = async () =>{
                const { width, height } = img;
                const newWidth = Math.floor(width / 3)*3;
                const newHeight = Math.floor(width / 3)*2;
                console.log(newWidth,newHeight,'===>');
                const canvas = document.createElement("canvas");
                canvas.width = newWidth;
                canvas.height = newHeight;
                const ctx = canvas.getContext("2d");
                ctx?.drawImage(img, 0, 0, newWidth, newHeight);
                const blobString = (await new Promise((resolve, reject) => {
                canvas.toBlob(async (blobFile) => {
                    const file2 = new File([blobFile], 'fileName', { type: 'jpg', lastModified: Date.now() });
                    const isLt5M = file2.size / 1024 / 1024 <= 10;
                    if (!isLt5M) {
                        this.$message.error("上传图片最大10M");
                        return
                    }
                    const formData = new FormData();
                    formData.append('file', file2);
                    updataJietu(this.IMG_API + "/oss/files",formData).then(res=>{
                        this.form.activityImage = res.redirect_uri;
                        this.$refs.ruleForm.validateField('activityImage')
                        this.visible2 = false
                        this.thumbUrl = ''
                        this.activityImagefileList = [{
                            uid:res.id,
                            name: res.name,
                            status: 'done',
                            url: res.redirect_uri
                        }]
                        console.log(res,'===========>');
                    })
                    resolve(blobFile);
                    });
                }).catch((err) => {
                    throw new Error(err);
                }))
                // 释放canvas ，避免内存泄露
                this.releaseCanvas(canvas);
            }
              img.src = URL.createObjectURL(file);
            // })
        },
        // 选择文件
        fileChange(e){
            let file = e.target.files[0];
            this.spinning = true
            const isLt5M = file.size / 1024 / 1024 <= 10;
            if (!isLt5M) {
                this.$message.error({content:"上传图片最大10M"});
                this.spinning = false
                return
            }
            var reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => { 
                this.thumbUrl = reader.result
                this.spinning = false
            } 
            reader.onerror = function(err) {
                console.log(err); 
                this.spinning = false
            }  
        },
        onChangeIsApply(){
            this.form1.isShowNeedNotRegister = 1
            this.form1.userRestriction = 0
            this.form1.enterpriseTypeIdIdList = []
            this.form1.applyTemplate = 1
            if(this.form1.isApply ==1){
                this.form1.personnelCeilingType =0;
                this.form1.personnelCeiling = ''
            }else{
                this.form1.personnelCeilingType =1
                this.form1.personnelCeiling = ''
            }
        },
        onChangepersonnelCeilingType(e){
            if(e.target.checked){
                this.form1.personnelCeilingType =2
            }else{
                this.form1.personnelCeilingType =1
            }
            this.form1.personnelCeiling = ''
            console.log(`checked = ${e.target.checked}`,this.personnelCeilingType);
        },
        onChangePersonnelCeiling(){
            if(!this.flag){
                if(this.form1.personnelCeiling<this.form1.count){
                    this.$message.error("限制人数不能小于已报名人数")
                    this.form1.personnelCeiling = ''
                    this.flag = true
                }
                if(this.form1.personnelCeiling>this.maxNum){
                    this.$message.error("限制人数超出最大范围")
                    this.form1.personnelCeiling = ''
                    this.flag = true
                }
                this.form1.personnelCeilingType =1
                setInterval(()=>{
                    this.flag = false
                },1000)
            }
          
        },
        activeBut(){
            if(this.form.activeList.length>=10){
                this.$message.error('最多添加10个活动时间')
                return false
            }
            this.form.activeList.push({maintenance:[{time:'',checkList:[],sessionList:[]}],dateList:[],projectId: undefined, siteId: undefined, maintenanceTimeList: []})
        },
        deleteActiveTime(index){
            this.form.activeList.splice(index,1)
        }
    }



}
</script>
<style scoped lang="scss">
.header {
    display: flex;
    justify-content: space-between;
}

::v-deep.ant-modal-wrap {
    z-index: 100000 !important;
}

::v-deep.ant-form-item-label {
    width: 150px !important;
}

.btn {
    height: 32px;
    width: 99.5px;
    text-align: center;
    line-height: 32px;
    border: 1px solid #ccc;
    border-radius: 5px;
    cursor: pointer;

    &+.btn {
        margin-left: 10px;
    }
}

.btn_c {
    border-color: #1890ff;
    color: #1890ff;
}

::v-deep .ant-calendar-picker-container {
    z-index: 10003;
}

::v-deep .ant-modal-content {
    position: relative;
    background-color: #fff;
    background-clip: padding-box;
    border: 0;
    border-radius: 2px;
    pointer-events: auto;
    max-width: 80vw;
    display: inline-block;
    left: 50%;
    transform: translate(-50%);
}

.ant-form-item {
    display: flex;
}

.btn_box {
    margin: 0 auto;
    display: flex;
    justify-content: center;
    margin-top: 40px;
}

.delfile {
    &:hover {
        color: #1890ff;
    }
}

.upload-box {
    width: 104px;
    height: 104px;
    margin-right: 8px;
    margin-bottom: 8px;
    text-align: center;
    vertical-align: top;
    background-color: #fafafa;
    border: 1px dashed #d9d9d9;
    border-radius: 4px;
    cursor: pointer;
    transition: border-color 0.3s ease;
    position: relative;
}

.displayFlex {
    display: flex;
    justify-content: flex-end;
}
</style>
<style>
.ant-message {
    z-index: 9999999 !important;
    /* 使用一个高于页面其他元素的 z-index 值 */
}
</style>