var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"content"},[_c('a-form-model',{ref:"ruleForm",attrs:{"model":_vm.formData,"rules":_vm.rules,"label-col":{ span: 4 },"wrapper-col":{ span: 21 }}},[_c('a-form-model-item',{attrs:{"label":"活动时间","prop":"dateList"}},[_c('div',{staticClass:"displayFlex",staticStyle:{"justify-content":"space-between"}},[_c('a-range-picker',{attrs:{"format":"YYYY-MM-DD HH:mm","showToday":true,"allowClear":"","getCalendarContainer":function (triggerNode) {
                return triggerNode.parentNode;
              },"disabled":_vm.formData.isnoEdit==0,"show-time":{
              hideDisabledOptions: true,
              defaultValue: [_vm.moment('00:00', 'HH:mm'), _vm.moment('23:59', 'HH:mm')],
              format: 'HH:mm',
            },"disabled-date":_vm.disabledDate,"placeholder":['开始时间', '结束时间']},on:{"change":_vm.onTimeChange},model:{value:(_vm.formData.dateList),callback:function ($$v) {_vm.$set(_vm.formData, "dateList", $$v)},expression:"formData.dateList"}})],1)]),_c('a-form-model-item',{attrs:{"label":"活动生效时间","prop":"radio"}},[_c('a-radio-group',{attrs:{"disabled":_vm.formData.isnoEdit==0},on:{"change":_vm.radioChange},model:{value:(_vm.formData.radio),callback:function ($$v) {_vm.$set(_vm.formData, "radio", $$v)},expression:"formData.radio"}},[_c('a-radio',{attrs:{"value":1}},[_vm._v("发布后立即生效")]),_c('a-radio',{attrs:{"value":2}},[_vm._v("发布后活动时间开始前")]),_c('a-input-number',{staticStyle:{"width":"100px"},attrs:{"precision":0,"min":1,"max":99,"disabled":_vm.formData.isnoEdit==0,"placeholder":""},on:{"input":function($event){return _vm.$forceUpdate()}},model:{value:(_vm.formData.days),callback:function ($$v) {_vm.$set(_vm.formData, "days", $$v)},expression:"formData.days"}}),_c('span',[_vm._v(" 天生效")])],1)],1),(_vm.$checkButtonPermissions(_vm.$buttonPermissionsConfig.ranking.enterpriseServiceActivitiesManagement.enterpriseServiceActiveMaintenance))?_c('div',{staticClass:"displayFlex"},[_c('div',{staticClass:"maintenanceTitle"},[_vm._v("场地维护：")]),_c('div',{staticClass:"maintenance"},[_c('div',{staticClass:"displayFlex"},[_c('div',{staticClass:"displayFlex flexCenter"},[_c('span',{staticClass:"width110"},[_vm._v("选择项目：")]),_c('a-select',{staticStyle:{"width":"180px"},attrs:{"placeholder":"请选择项目","getPopupContainer":function (triggerNode) {
                    return triggerNode.parentNode || _vm.document.body;
                  },"disabled":_vm.formData.isnoEdit==0,"allowClear":true},on:{"change":_vm.projectChange},model:{value:(_vm.formData.projectId),callback:function ($$v) {_vm.$set(_vm.formData, "projectId", $$v)},expression:"formData.projectId"}},_vm._l((_vm.projectList),function(item){return _c('a-select-option',{key:item.projectId,attrs:{"value":item.projectId}},[_vm._v(" "+_vm._s(item.projectName)+" ")])}),1)],1),(_vm.formData.projectId)?_c('div',{staticClass:"displayFlex flexCenter marginLeft20"},[_c('span',{staticStyle:{"width":"140px","text-align":"right"}},[_vm._v("选择活动场地：")]),_c('a-select',{staticStyle:{"width":"180px"},attrs:{"placeholder":"请选择活动场地","disabled":_vm.formData.isnoEdit==0,"getPopupContainer":function (triggerNode) {
                    return triggerNode.parentNode || _vm.document.body;
                  }},on:{"change":_vm.siteChange},model:{value:(_vm.formData.siteId),callback:function ($$v) {_vm.$set(_vm.formData, "siteId", $$v)},expression:"formData.siteId"}},_vm._l((_vm.siteList),function(item){return _c('a-select-option',{key:item.facility_id,attrs:{"value":item.facility_id}},[_vm._v(" "+_vm._s(item.facility_name)+" ")])}),1)],1):_vm._e()]),(_vm.formData.siteId)?_c('div',_vm._l((_vm.formData.maintenance),function(item,index){return _c('div',{key:index,staticClass:"displayFlex marginTop30"},[_c('div',{staticClass:"displayFlex",staticStyle:{"height":"30px"}},[_c('span',{staticClass:"width110 lineHeight30"},[_vm._v("选择维护时间：")]),_c('a-select',{staticStyle:{"width":"180px"},attrs:{"placeholder":"请选择维护时间","disabled":_vm.formData.isnoEdit==0,"getPopupContainer":function (triggerNode) {
                      return triggerNode.parentNode || _vm.document.body;
                    }},on:{"change":function($event){return _vm.maintenanceChange(index)}},model:{value:(item.time),callback:function ($$v) {_vm.$set(item, "time", $$v)},expression:"item.time"}},_vm._l((_vm.maintenanceSelectList),function(item){return _c('a-select-option',{key:item.rule_date,attrs:{"value":item.rule_date}},[_vm._v(" "+_vm._s(item.rule_date)+" ")])}),1),(index == 0)?_c('a-button',{staticClass:"marginLeft10",attrs:{"disabled":_vm.formData.isnoEdit==0,"shape":"circle","icon":"plus"},on:{"click":_vm.addMaintenance}}):_vm._e(),(index != 0)?_c('a-button',{staticClass:"marginLeft10",attrs:{"disabled":_vm.formData.isnoEdit==0,"shape":"circle","icon":"minus"},on:{"click":function($event){return _vm.deleteMaintenance(index)}}}):_vm._e()],1),_c('div',{staticClass:"displayFlex marginLeft20"},[_c('span',{staticClass:"lineHeight30",staticStyle:{"width":"98px","text-align":"right"}},[_vm._v("选择活动场次：")]),_c('div',{staticStyle:{"width":"200px"}},[_c('a-checkbox-group',{model:{value:(item.checkList),callback:function ($$v) {_vm.$set(item, "checkList", $$v)},expression:"item.checkList"}},_vm._l((item.maintenanceTimeList),function(ele,index){return _c('a-checkbox',{key:index,attrs:{"disabled":ele.type != 0||_vm.formData.isnoEdit==0,"value":ele.value}},[_vm._v(_vm._s(ele.label))])}),1)],1)])])}),0):_vm._e(),_c('div',{staticClass:"displayFlex marginTop30"},[_c('div',{staticClass:"displayFlex flexCenter",staticStyle:{"flex":"1"}},[_c('span',{staticClass:"width110"},[_vm._v("备注：")]),_c('a-textarea',{staticStyle:{"width":"80%"},attrs:{"maxLength":100,"disabled":_vm.formData.isnoEdit==0,"placeholder":"请输入备注，最多不超过100字","auto-size":{ minRows: 3, maxRows: 5 }},model:{value:(_vm.formData.notice),callback:function ($$v) {_vm.$set(_vm.formData, "notice", $$v)},expression:"formData.notice"}})],1)])])]):_vm._e()],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }