<template>
  <div>
    <div class="content">
      <a-form-model
        ref="ruleForm"
        :model="formData"
        :rules="rules"
        :label-col="{ span: 4 }"
        :wrapper-col="{ span: 21 }"
        
      >
        <a-form-model-item label="活动时间" prop="dateList">
          <div class="displayFlex" style="justify-content: space-between">
            <a-range-picker
              format="YYYY-MM-DD HH:mm"
              :showToday="true"
              allowClear
              :getCalendarContainer="
                (triggerNode) => {
                  return triggerNode.parentNode;
                }
              "
              :disabled="formData.isnoEdit==0"
              :show-time="{
                hideDisabledOptions: true,
                defaultValue: [moment('00:00', 'HH:mm'), moment('23:59', 'HH:mm')],
                format: 'HH:mm',
              }"
              :disabled-date="disabledDate"
              :placeholder="['开始时间', '结束时间']"
              @change="onTimeChange"
              v-model="formData.dateList"
            />
          </div>
        </a-form-model-item>
        <a-form-model-item label="活动生效时间" prop="radio">
          <a-radio-group :disabled="formData.isnoEdit==0" @change="radioChange" v-model="formData.radio">
            <a-radio :value="1">发布后立即生效</a-radio>
            <a-radio :value="2">发布后活动时间开始前</a-radio
            ><a-input-number
              :precision="0"
              v-model="formData.days"
              :min="1"
              :max="99"
              :disabled="formData.isnoEdit==0"
              @input="$forceUpdate()"
              style="width: 100px"
              placeholder=""
            />
            <span> 天生效</span>
          </a-radio-group>
        </a-form-model-item>
        <div  v-if="$checkButtonPermissions($buttonPermissionsConfig.ranking.enterpriseServiceActivitiesManagement.enterpriseServiceActiveMaintenance)" class="displayFlex">
        <!-- <div  class="displayFlex"> -->
          <div class="maintenanceTitle">场地维护：</div>
          <div class="maintenance">
            <div class="displayFlex">
              <div class="displayFlex flexCenter">
                <span class="width110">选择项目：</span>
                <a-select
                  placeholder="请选择项目"
                  :getPopupContainer="
                    (triggerNode) => {
                      return triggerNode.parentNode || document.body;
                    }
                  "
                  :disabled="formData.isnoEdit==0"
                  style="width: 180px"
                  v-model="formData.projectId"
                  @change="projectChange"
                  :allowClear="true"
                >
                  <a-select-option v-for="item in projectList" :value="item.projectId" :key="item.projectId">
                    {{ item.projectName }}
                  </a-select-option>
                </a-select>
              </div>
              <div v-if="formData.projectId" class="displayFlex flexCenter marginLeft20">
                <span style="width: 140px; text-align: right">选择活动场地：</span>
                <a-select
                  @change="siteChange"
                  v-model="formData.siteId"
                  placeholder="请选择活动场地"
                  :disabled="formData.isnoEdit==0"
                  :getPopupContainer="
                    (triggerNode) => {
                      return triggerNode.parentNode || document.body;
                    }
                  "
                  style="width: 180px"
                >
                  <a-select-option v-for="item in siteList" :value="item.facility_id" :key="item.facility_id">
                    {{ item.facility_name }}
                  </a-select-option>
                </a-select>
              </div>
            </div>
            <div v-if="formData.siteId">
              <div v-for="(item, index) in formData.maintenance" class="displayFlex marginTop30" :key="index">
                <div class="displayFlex" style="height: 30px">
                  <span class="width110 lineHeight30">选择维护时间：</span>
                  <a-select
                    v-model="item.time"
                    placeholder="请选择维护时间"
                    :disabled="formData.isnoEdit==0"
                    @change="maintenanceChange(index)"
                    :getPopupContainer="
                      (triggerNode) => {
                        return triggerNode.parentNode || document.body;
                      }
                    "
                    style="width: 180px"
                  >
                    <a-select-option
                      v-for="item in maintenanceSelectList"
                      :value="item.rule_date"
                      :key="item.rule_date"
                    >
                      {{ item.rule_date }}
                    </a-select-option>
                  </a-select>
                  <a-button :disabled="formData.isnoEdit==0" @click="addMaintenance" v-if="index == 0" class="marginLeft10" shape="circle" icon="plus" />
                  <a-button
                    @click="deleteMaintenance(index)"
                    :disabled="formData.isnoEdit==0"
                    v-if="index != 0"
                    class="marginLeft10"
                    shape="circle"
                    icon="minus"
                  />
                </div>
                <div class="displayFlex marginLeft20">
                  <span style="width: 98px; text-align: right" class="lineHeight30">选择活动场次：</span>
                  <div style="width: 200px">
                    <a-checkbox-group v-model="item.checkList">
                      <a-checkbox
                        v-for="(ele, index) in item.maintenanceTimeList"
                        :disabled="ele.type != 0||formData.isnoEdit==0"
                        :value="ele.value"
                        :key="index"
                        >{{ ele.label }}</a-checkbox
                      >
                    </a-checkbox-group>
                    <!-- <a-checkbox-group v-model="item.checkList" :options="item.maintenanceTimeList" /> -->
                  </div>
                </div>
              </div>
            </div>
            <div class="displayFlex marginTop30">
              <div class="displayFlex flexCenter" style="flex: 1">
                <span class="width110">备注：</span>
                <a-textarea
                  style="width: 80%"
                  :maxLength="100"
                  :disabled="formData.isnoEdit==0"
                  v-model="formData.notice"
                  placeholder="请输入备注，最多不超过100字"
                  :auto-size="{ minRows: 3, maxRows: 5 }"
                />
              </div>
            </div>
          </div>
        </div>
      </a-form-model>
    </div>
  </div>
</template>

<script>
import * as api from '@/api/enterpriseServiceActivitiesManagement';
import moment from 'moment';
export default {
  props: ['formData', 'index', 'projectList'],
  data() {
    return {
      // formData: { ...this.form, dateList: [], projectId: undefined, siteId: undefined, maintenanceTimeList: [] },
      siteList: [],
      maintenanceSelectList: [],
      isFirst: true,
      rules: {
        dateList: [{ required: true, message: '此为必填项', trigger: 'change' }],
        radio: [{ required: true, message: '此为必填项', trigger: 'change' }],
      },
    };
  },
  methods: {
    childProcessingChange() {
      if (this.formData.projectId) {
        this.getSiteList();
      }
      if (this.formData.siteId && this.formData.projectId) {
        this.getMaintenanceList();
      }
      if (this.formData.maintenance.length) {
        this.formData.maintenance.forEach((item, index) => {
          this.dataProcessing(item, index);
        });
      }
    },
    //场地维护编辑数据处理
    async dataProcessing(newVal, index) {
      let obj = {
        projectId: this.formData.projectId,
        facilityId: this.formData.siteId,
        appointmentDate: newVal.time,
      };
      //获取维护时间段
      const res = await api.getsiteTimeList(obj);
      if (res.code == 'SUCESS' && res.data) {
        let list = [];
        res.data.forEach((element) => {
          list.push({
            label: element.start_time + '-' + element.end_time,
            value: element.start_time + '-' + element.end_time,
            type: element.type,
          });
        });
        newVal.maintenanceTimeList = list;
      } else {
        newVal.maintenanceTimeList = [];
      }
      this.formData.maintenance[index] = newVal;
      this.$forceUpdate();
    },
    moment,
    onTimeChange(value, dateString) {
      this.formData.projectId = undefined;
      this.formData.maintenance = [{ time: undefined, checkList: [], sessionList: [] }];
    },
    disabledDate(current) {
      //  禁用当前时间之前的时间
      return current && current < moment().subtract(1, 'days').endOf('day');
    },
    radioChange() {
      if (this.formData.radio == 2) {
        this.formData.days = 1;
      } else {
        this.formData.days = '';
      }
    },
    addMaintenance() {
      if (this.formData.maintenance.length >= 7) {
        this.$message.error('最多添加7个维护时间');
        return false;
      }
      this.formData.maintenance.push({ time: undefined, checkList: [], sessionList: [] });
      this.$forceUpdate();
    },
    deleteMaintenance(index) {
      this.formData.maintenance.splice(index, 1);
    },
    //获取活动场地下拉数据
    async projectChange() {
      if (this.formData.projectId) {
        this.formData.siteId = undefined;
        this.getSiteList();
      } else {
        this.formData.siteId = undefined;
        this.formData.maintenance.push({ time: undefined, checkList: [], sessionList: [] });
      }
    },
    async getSiteList() {
      const res = await api.getplacList(this.formData.projectId);
      if (res.code == 'SUCESS' && res.data) {
        this.siteList = res.data;
      } else {
        this.siteList = [];
      }
    },
    //活动场地change
    siteChange() {
      if (this.formData.dateList.length != 2) {
        return;
      }

      if (!this.formData.siteId) {
        return;
      }
      this.formData.maintenance = [{ time: undefined, checkList: [], sessionList: [] }];

      this.getMaintenanceList();
    },
    //获取维护时间下拉数据
    async getMaintenanceList() {
      let obj = {
        project_id: this.formData.projectId,
        facility_id: this.formData.siteId,
        start_date: this.moment(this.formData.dateList[0]).format('YYYY-MM-DD'),
        end_date: this.moment(this.formData.dateList[1]).format('YYYY-MM-DD'),
      };
      const res = await api.getplaceDateList(obj);
      if (res.code == 'SUCESS' && res.data) {
        this.maintenanceSelectList = res.data;
      } else {
        this.maintenanceSelectList = [];
      }
    },
    //维护时间change
    async maintenanceChange(index) {
      let nowTime = this.formData.maintenance[index].time;
      let type = true;
      this.formData.maintenance.forEach((item, i) => {
        if (item.time == nowTime && index != i) {
          type = false;
        }
      });
      if (!type) {
        this.$message.error('请勿重复选择一个时间');
        return false;
      }
      this.formData.maintenance[index].checkList = [];
      let obj = {
        projectId: this.formData.projectId,
        facilityId: this.formData.siteId,
        appointmentDate: this.formData.maintenance[index].time,
      };
      //获取维护时间段
      const res = await api.getsiteTimeList(obj);
      if (res.code == 'SUCESS' && res.data) {
        let list = [];
        res.data.forEach((element) => {
          list.push({
            label: element.start_time + '-' + element.end_time,
            value: element.start_time + '-' + element.end_time,
            type: element.type,
          });
        });
        this.formData.maintenance[index].maintenanceTimeList = list;
      } else {
        this.formData.maintenance[index].maintenanceTimeList = [];
      }
      this.$forceUpdate();
    },
    //校验form表单
    checkForm() {
      let data = null;
      this.$refs.ruleForm.validate(async (valid) => {
        if (valid) {
          let list = [];
          let maintainTime = '';
          //获取维护时间 逻辑处理
          this.formData.maintenance.forEach((item) => {
            if (item.checkList.length) {
              let str = item.time + ' ' + item.checkList.join('、');
              list.push(str);
            }
          });
          let publishType = '';
          //活动发布时间数据处理
          if (this.formData.radio == 1) {
            publishType = 0;
          } else {
            if (this.formData.days) {
              publishType = this.formData.days;
            } else {
              publishType = 1;
            }
          }
          maintainTime = list.join(';');
          let projectData = '';
          if (this.formData.projectId) {
            //后端要求传项目名称 （懒得查）
            projectData = this.projectList.find((item) => item.projectId == this.formData.projectId);
          } else {
            projectData = {
              projectName: '',
            };
          }

          let siteData = '';
          if (this.formData.siteId) {
            //后端要求传场地名称 （懒得查）
            siteData = this.siteList.find((item) => item.facility_id == this.formData.siteId);
          } else {
            siteData = { facility_name: '' };
          }

          let obj = {
            startTime: this.formData.dateList[0].format('YYYY-MM-DD HH:mm') + ':00',
            endTime: this.formData.dateList[1].format('YYYY-MM-DD HH:mm') + ':00',
            publishType: publishType,
            projectId: this.formData.projectId,
            projectName: projectData.projectName,
            siteId: this.formData.siteId,
            siteName: siteData.facility_name,
            notice: this.formData.notice,
            maintainTime: maintainTime,
            id:this.formData.activityId||null
          };
          data = obj;
        }
      });
      return data;
    },
  },
};
</script>

<style scoped lang="scss">
.content {
  border: 1px solid #e8e8e8;
  padding: 30px 0px;
  margin-left: 120px;
}

.maintenanceTitle {
  width: 16.66666667%;
  text-align: right;
}

.maintenance {
  border: 1px solid #e8e8e8;
  padding: 30px 0px;
  width: 80%;
}

.marginTop30 {
  margin-top: 30px;
}

.displayFlex {
  display: flex;
}

.flexCenter {
  align-items: center;
}

.flexStart {
  align-items: start;
}

.width110 {
  width: 110px;
  text-align: right;
}

.marginLeft20 {
  margin-left: 15px;
}

.marginLeft10 {
  margin-left: 10px;
}

.lineHeight30 {
  line-height: 30px;
}
::v-deep .ant-checkbox-wrapper + .ant-checkbox-wrapper {
  margin-left: 0;
}
::v-deep .ant-select-dropdown {
  z-index: 99999;
}
</style>
